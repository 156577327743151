<template>
    <div class="md-layout md-centered container md-alignment-center">
        <md-card class="md-layout-item md-size-95 header">
            <md-card-header class="md-layout md-alignment-center">
                <div class="md-layout-item md-size-50 md-xsmall-size-100">
                    <table>
                        <tr>
                            <td style="width: fit-content; text-align: left; white-space: nowrap;">
                                <h2>
                                    Unsere Philosophie:
                                    <br><br><br>
                                    PAW überwindet Grenzen
                                </h2>
                            </td>
                            <td>
                                <img src="../assets/logo.svg" style="float: right; max-width: 150px" alt="PAW-Logo"/>
                            </td>
                        </tr>
                    </table>
                </div>
            </md-card-header>
        </md-card>
        <div class="md-layout-item md-size-100">
            <br>&nbsp;
        </div>
        <md-card class="md-layout-item md-centered manual md-size-45 md-xsmall-size-95">
            <md-card-media>
                <a href="/download/PAW.Flyer.1.svg">
                    <img alt="Flyer Seite 2" class="manual" src="../assets/philosophy/PAW.Flyer.1.jpg">
                </a>
            </md-card-media>
        </md-card>
        <md-card class="md-layout-item md-centered manual md-size-45 md-xsmall-size-95">
            <md-card-media>
                <a href="/download/PAW.Flyer.2.svg">
                <img alt="Flyer Seite 2" class="manual" src="../assets/philosophy/PAW.Flyer.2.jpg">
                </a>
            </md-card-media>
        </md-card>
        <div class="md-layout-item md-size-100 md-centered">
            <br>&nbsp;<br>
            <md-button class="md-button md-accent md-raised" href="/download/PAW_Flyer.pdf" target="_blank">
                Flyer als PDF
            </md-button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    div.container {
        align-content: center;
        text-align: center;
        margin: auto;
    }
    .md-card {
        align-content: center;
        text-align: center;
        margin: auto;
    }
    .header {
        background-color: rgba(255, 255, 255, 0.5);
    }
</style>

<script>
    export default {
        name: 'All_Philosophy',
        props: {},
        components: {},
    };
</script>
